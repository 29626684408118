<template>
  <div >
    <v-card-text >

      <v-row justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-text-field
            ref="CorreoElectronico"
            v-model="email"
            counter
            maxlength="80"
            prepend-inner-icon="mdi-email"
            required
          >
            <template #label>
              <div class="text-center">Correo Electrónico Facturas Electrónicas</div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-card-actions class="justify-end">

        <v-btn
            text
            style="background-color:rgb(204 33 40);color:white !important"
            @click="updateEmail()"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
      <v-alert
          v-show="successAlert"
          type="success"
          text
      >
        {{ msgSuccessAlert }}
      </v-alert>
    </v-card-text>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'campoClientEmailFacturacion',
  props: ['token_enterprise', 'token_password', 'email'],

  data () {
    return {
      dataUpdateEmail: [],
      msgSuccessAlert: 'Datos actualizados exitosamente',
      successAlert: false,
    }
  },
  computed: {
    ...mapState('clients', [
      'updateEmailFEResponse'
    ]),

    updateEmailFEResponse () {
      return this.$store.state.clients.updateEmailFEResponse
    },
  },
  watch:
      {
        updateEmailFEResponse  () {
          if (this.updateEmailFEResponse === true) {
            this.generateAlertSuccess()
            this.$store.dispatch('clients/setUpdateResponseEmailFE', false)
          }
        },
      },
  methods: {
    generateAlertSuccess () {
      this.successAlert = true
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    async updateEmail () {
      this.dataUpdateEmail.email = this.email
      this.dataUpdateEmail.token_password = this.token_password
      this.dataUpdateEmail.token_enterprise = this.token_enterprise
      this.dataUpdateEmail.user = JSON.parse(atob(localStorage.getItem('uid'))).user
      await this.$store.dispatch('clients/updateEmailFE', this.dataUpdateEmail)
    },
  },
}
</script>

<style scoped>

</style>
